
import { Options, Vue } from 'vue-class-component';
import { AppModalClient, AppUserClient, MatchClient, PremiumClient, TryAgainClient } from '@/services/Services';
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import Uncompletedprofile from '../components/uncompletedprofile.vue';
import MinimumUserNotCompletedYet from '../components/minimumUserNotCompletedYet.vue';
import FinderMatches from '../components/finderMatches.vue';
import FilterModal from '../modals/filterModal.vue';
import viewDescriptionModal from '../modals/viewDescriptionModal.vue';
import notificationIcon from '../components/notificationIcon.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import premiumActiveModal from '../modals/premium/premiumActiveModal.vue';
import AppInfoModal from '@/components/modals/appInfoModal.vue';
import NextFinderInfoModal from '../modals/nextFinderInfoModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import useTryAgainModal from '../modals/useTryAgainModal.vue';
import PremiumBuySuccessModal from '../modals/premium/premiumBuySuccessModal.vue';
import { UrlServices } from '@/services/UrlServices';
import AcceptAffinityFinderInfoModal from '../modals/acceptAffinityFinderInfoModal.vue';
import PreviewOtherPofileModal from '../modals/previewOtherPofileModal.vue';

@Options({
    components: {
        MinimumUserNotCompletedYet,
        Uncompletedprofile,
        FinderMatches,
        notificationIcon
    }
})
export default class Finder extends Vue {

    match: OM.NewMatchVm = null;
    filter: OM.FilterVM = new OM.FilterVM();
    premium: OM.PremiumActiveVM = null;
    appModalList: OM.AppModalVM[] = [];
    
    created() {
        Promise.all([
            AppUserClient.checkIfProfileCompleted(),
            PremiumClient.getActive(),
            AppModalClient.getAllVisibleOnApp(),
            MatchClient.getNewMatch(),
            AppUserClient.getFilter()
        ])
        .then(xs => {
            store.state.loggedUser.profileCompleted = xs[0];
            this.premium = xs[1];
            this.appModalList = xs[2];
            this.match = xs[3];
            this.filter = xs[4];

            this.showPremiumActiveModal();
        })
    } 

    showPremiumActiveModal(){
        if(!this.premium || store.state.premiumActiveModalSeen) {
            this.showInfoModals();
            return;
        }

        store.state.premiumActiveModalSeen = true;

        this.$opModal.show(premiumActiveModal, {
            premium: this.premium
        }, () => {
            this.showInfoModals();
        })
    }

    showInfoModals(index: number = 0) {
        if(this.appModalList.length == 0 || this.appModalList.length < (index + 1))
            return;

        var modal = this.appModalList[index];

        this.$opModal.show(AppInfoModal, {
            model: modal
        }, () => {
            AppModalClient.readModal(modal.identifier, false);

            if(!modal.actionUrl)
                this.showInfoModals(index + 1);
        })
    }

    getNewMatch() {
        MatchClient.getNewMatch()
		.then(x => {
            this.match = x;
		})
    }

    openDescription(){
        if(!this.match.biography)
            return;

        this.$opModal.show(viewDescriptionModal, {
            ownDescription: false,
            completeName: this.match.personName,
            birthData: this.match.birthDate,
            meters: this.match.distanceMeters,
            sexualOrientation: this.match.sexualOrientation,
            description: this.match.biography
        })
    }

    openExperienceList() {
        this.$router.push('/experience-list/' + this.match.appUserIdentifier);
    }
    
    openFilter(){
        this.$opModal.show(FilterModal, {
            filter: this.filter,
            reload: (filter: OM.FilterVM) => {
                this.filter = filter;
                this.getNewMatch();
            }
        })
    }

    next(){
        var dontNextShowMessage = StorageServices.getDontShowNextProfileMessage();
        if(dontNextShowMessage){
            this.refuseAffinityAndGoNext();
            return;
        }
        
        this.$opModal.show(NextFinderInfoModal, {
            nextProfile: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowNextProfileMessage(dontShowAnymore);
                this.$opModal.closeLast();
                this.refuseAffinityAndGoNext();
            },
            stay: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowNextProfileMessage(dontShowAnymore);
                this.$opModal.closeLast();
            }
        })
    }

    refuseAffinityAndGoNext() {
        CommonServices.showSpinner();

        MatchClient.refuseAffinity(this.match.matchIdentifier)
        .then( x => {
            MatchClient.getNewMatch()
            .then( x => {
                this.match = x;
                
                CommonServices.hideSpinner();
                var elements = document.getElementsByClassName("full_horizontal_scroll");
                
                if(elements.length == 0)
                    return;

                var element = elements[0];
                element.scrollLeft = 0;
            })
        })
        .catch(err => {
            CommonServices.hideSpinner();
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: err.CallbackParams != null ? err.CallbackParams.CallbackText : this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    if(err.CallbackParams){
                        if(err.CallbackParams.OpenBlank)
                            UrlServices.openBlank(err.CallbackParams.CallbackRoute)
                        else 
                            this.$router.push(err.CallbackParams.CallbackRoute);
                    }

                    this.$opModal.closeLast();
                },
                deny: err.RefuseCallbackParams != null ? err.RefuseCallbackParams.CallbackText : null,
                denyCb: err.RefuseCallbackParams == null ? null : () => {
                    if(err.RefuseCallbackParams.OpenBlank)
                        UrlServices.openBlank(err.RefuseCallbackParams.CallbackRoute)
                    else 
                        this.$router.push(err.RefuseCallbackParams.CallbackRoute);

                    this.$opModal.closeLast();
                }
            })
        })
    }

    reply(matchIdentifier: string) {
        MatchClient.checkIfCanReply()
        .then(x => {
            this.$router.push('/new-match/' + matchIdentifier)
        })
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                },
            })
        })
    }

    createChatCheck(matchIdentifier: string) {
        var dontShowCreateAffinityMessage = StorageServices.getDontShowCreateAffinityMessage();

        if(dontShowCreateAffinityMessage){
            this.afterCreateChatCheck(matchIdentifier);
            return;
        }

        this.$opModal.show(AcceptAffinityFinderInfoModal, {
            accept: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowCreateAffinityMessage(dontShowAnymore);
                this.$opModal.closeLast();
                this.afterCreateChatCheck(matchIdentifier);
            },
            stay: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowCreateAffinityMessage(dontShowAnymore);
                this.$opModal.closeLast();
            }
        })
    }

    afterCreateChatCheck(matchIdentifier: string){
        var confirmAffinityVM: OM.ConfirmAffinityVM = {
            affinityIdentifier: matchIdentifier
        }

        MatchClient.confirmAffinity(confirmAffinityVM)
        .then(x => {
            this.match.chatRoomIdentifier = x;
            this.$router.push('/chats/' + this.match.chatRoomIdentifier);
        })
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                },
            })
        })
    }

    viewQuestionAndAnswers(matchIdentifier: string){
        this.$opModal.show(PreviewOtherPofileModal, {
            match: this.match,
            replyCallback: () => {
                this.$opModal.closeLast();
                this.reply(matchIdentifier);
            }
        })
    }
}
