import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal full bg_white" }
const _hoisted_2 = { class: "modal_header" }
const _hoisted_3 = { class: "modal_body text-center p-0" }
const _hoisted_4 = { class: "modal_footer flex_spacebetween_centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_premium_active = _resolveComponent("premium-active")
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { white: false }, {
        right: _withCtx(() => [
          _createVNode("img", {
            src: _imports_0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$opModal.closeLast()))
          })
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_premium_active, { premiumModel: _ctx.premium }, null, 8, ["premiumModel"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        class: "fev_button bg_brand",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$opModal.closeLast()))
      }, [
        _createVNode(_component_localized_text, {
          class: "fev_text5 fw_800",
          localizedKey: "app_Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}