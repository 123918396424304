
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";
import * as VM from "@/viewModel";
import store from '@/store';
import { AppUserClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import { CommonServices } from '@/services/CommonServices';
import { getFromGoogleFilterAddressVMToAddress, getMaxRangeLabel } from '@/utils';

@Options({
    components: {
    }
})
export default class FilterModal extends Vue {

    @Prop() filter: OM.FilterVM;
    @Prop() reload: (filter: OM.FilterVM) => void;

    localFilter: OM.FilterVM = new OM.FilterVM();
    locationSettings: OM.RangeConfiguration = store.state.consts.config.positionRange;
    ageSettings: OM.AgeRangeConfiguration = store.state.consts.config.ageRange;
    googleFilterAddressVM : VM.GoogleFilterAddressVM = new VM.GoogleFilterAddressVM();

    created(){
        this.localFilter = JSON.parse(JSON.stringify(this.filter))
    }

    get getMaxRange(){
        return getMaxRangeLabel(this.localFilter.ageRange, this.ageSettings);
    }

    save(){
        if(this.googleFilterAddressVM.changed)
            this.saveChangedAddress();
        else 
            this.saveNotChangedAddress(false);
    }

    async saveChangedAddress(){
        var hideShowSpinner = false;

        if(this.googleFilterAddressVM.addressFixed){
            hideShowSpinner = true;
            CommonServices.showSpinner();
        }

        this.localFilter.currentPosition = await getFromGoogleFilterAddressVMToAddress(this.googleFilterAddressVM);

        this.saveNotChangedAddress(hideShowSpinner);
    }


    saveNotChangedAddress(hideShowSpinner: boolean){
        AppUserClient.updateFilter(this.localFilter)
        .then( x => {
            if(this.localFilter.ageRange.maxAge >= this.ageSettings.maxVisible)
                this.localFilter.ageRange.maxAge = this.ageSettings.max;

            this.reload(this.localFilter);
            this.$opModal.closeLast();
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                },
            })
        }).finally( () => {
            if(hideShowSpinner)
                CommonServices.hideSpinner();
        })
    }

    setGender(gender: string){
        this.localFilter.lookingFor = Number.parseInt(gender);
    }

    UpdateValues(val: any){
        this.localFilter.ageRange.minAge = val.minValue;
        this.localFilter.ageRange.maxAge = val.maxValue;
    }
}
