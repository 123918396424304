
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class AcceptAffinityFinderInfoModal extends Vue {

    dontShowAnymore: boolean = false;

    @Prop() accept: (dontShowAnymore: boolean) => void;
    @Prop() stay: (dontShowAnymore: boolean) => void;

    created() {

    }

}
