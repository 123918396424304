
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Uncompletedprofile extends Vue {

    minimumRequiredQuestionsNumber: number = store.state.consts.config.questions.minimumRequiredQuestionsNumber;

    @Prop({
        default: false
    }) isExperiencePage: boolean;

}
