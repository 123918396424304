
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import * as OM from '@/Model';
import premiumActive from '../../components/premium/premiumActive.vue';

@Options({
    components: {
        premiumActive
    }
})
export default class premiumActiveModal extends Vue {

    @Prop() premium: OM.PremiumActiveVM;

    created() {
    }

}
