
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import { PreviewQuestionClient } from '@/services/Services';

@Options({})
export default class MinimumUserNotCompletedYet extends Vue {

    previewQuestions: OM.PreviewQuestionVM[] = [];
    loaded = false;

    created() {
        PreviewQuestionClient.getAllPublished()
        .then( x => {
            this.previewQuestions = x;
            this.loaded = true;
        })
    }

}
