
import { AppUserClient, MatchClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import PersonInfo from '@/components/personInfo.vue';
import Hexagon from '@/components/hexagon.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import ReportQuestion from '@/components/reportQuestion.vue';
import ViewDescriptionModal from '../modals/viewDescriptionModal.vue';
import bodymovin, { AnimationItem } from 'lottie-web';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
        PersonInfo,
        LoveFaceShadowBar,
        ReportQuestion,
        Hexagon
    }
})
export default class FinderMatches extends Vue {

    @Prop() match: OM.NewMatchVm;
    @Prop() filter: OM.FilterVM;

    lottieExperience: AnimationItem = null;
    lottieIsInPlayStatus: boolean = false;

    @Watch('match')
    startLottieAnimation() {
        if(this.match && this.match.hasAnyExperience) {

            if(this.lottieIsInPlayStatus){
                this.lottieExperience.destroy();
                this.lottieIsInPlayStatus = false;
            }

            setTimeout(() => {
                this.lottieExperience = bodymovin.loadAnimation({
                    container: <any>this.$refs.lottieExperience,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: './json/experience_loop.json',
                })

                this.lottieIsInPlayStatus = true;

                this.lottieExperience.play();
            }, 300);
        }
    }
    
    get affinityRateText(){
        if(this.match.affinityRateReachedFromOther != null)
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}Di{{Name}}", "Affinity Rate di {{name}}: {{rate}}%", "", "", { rate: this.match.minimumAffinityRate, name: this.$filters.cutNameTooLong(this.match.personName) });   
        else
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.match.minimumAffinityRate });   
    }

    viewQuestionAndAnswers(){
        this.$emit("viewQuestionAndAnswers");
    }

    setFindUserOutsideMyRange($event: any) {

        if($event == this.filter.findUserOutsideMyRange)
            return;

        AppUserClient.setFindUserOutsideMyRange($event, false)
        .then(x => {
            this.filter.findUserOutsideMyRange = $event;
            this.$emit("getNewMatch");
        });
    }
}
