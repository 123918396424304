
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({  
    components: {}
})
export default class PreviewOtherPofileModal extends Vue {

    @Prop() match: OM.NewMatchVm;
    @Prop() replyCallback: () => void;

    localIndex: number = 0;
    affinityRateText: string = "";

    created(){
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");   
        this.affinityRateText += " " + this.match.minimumAffinityRate + "%"; 
    }

    get replyStatusNotCompleted(){
        return this.match.questions.filter( x => x.answerIndex == -1).length > 0;
    }
}
