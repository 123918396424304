
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class NextFinderInfoModal extends Vue {

    dontShowAnymore: boolean = false;

    @Prop() nextProfile: any;
    @Prop() stay: any;

    created() {

    }

}
